import React from "react";
import "./What.scss";
import ClickImage from "../../reuse/ClickImage/ClickImage";
import icdHuman from '../../../assets/images/ICD in body - from video.png'
import icdScar from '../../../assets/images/ICD scar on body - from video.png'

const What = () => {
  return (
    <div className="what">
      <div className="whatIsIcd">
        <h4>What is an ICD?</h4>
        <p>
          An ICD is a small device that is placed under the skin of the chest.
          Wires (called “leads”) connect the ICD to the heart. An ICD is
          designed to prevent an at-risk person from dying suddenly from a
          dangerous heart rhythm. When it senses a dangerous heart rhythm, an
          ICD gives the heart an electrical shock. It does this in order to get
          the heart to beat normally. An ICD is different than a pacemaker. A
          pacemaker helps the heart beat but does not give a shock like an ICD.
        </p>
      </div>

      <div className="isIcdRight">
        <h4>Is an ICD right for me?</h4>
        <p>
          Your doctor has suggested that you might benefit from having an ICD.
          This is a big decision. Understanding what to expect after getting an
          ICD might help you to feel better about your decision. The ICD may not
          be right for some people. Although this may be hard to think about,
          other patients like you have wanted to know this information.
        </p>
      </div>

      <div className="icdBody">
        <div className="bodyIcdImage">
          <h4>Where ICD fits in the body?</h4>
          <img src={icdHuman} alt="" />
          <img src={icdScar} alt="" />
        </div>

        <div className="clickIcdImage">
            <ClickImage/>
        </div>
      </div>
    </div>
  );
};

export default What;
