import React from "react";
import "./Footer.scss";
import firstFooter from "../../../assets/images/CPCCD_logo.png";
import secondFooter from "../../../assets/images/UCD SoM.jpg";

const Footer = () => {
  return (
    <div className="footer">
     <div className="footerBox">
     <div className="firstFooter">
        <img src={firstFooter} alt="" />
      </div>

      <div className="secondFooter">
        <div className="secondFooterImg">
          <img src={secondFooter} alt="" />
        </div>
      </div>
     </div>
    </div>
  );
};

export default Footer;
