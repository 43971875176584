import React from "react";
import "./QuestionConcerns.scss";
import SpeechBubble from "../../reuse/SpeechBubble/SpeechBubble";

const QuestionConcerns = ({ setQuestions, Questions }) => {
  // const handleKeyPress = (e) => {
  //   console.log(e.key);

  //   if(e.key === 'Enter'){
  //     console.log(Questions.split('/'));
  //   }
  // }

  const bubbleContent = "You know what is important to you better than anyone else. Any decision about your treatment should be based on your goals and values!";

  return (
    <div className="questionConcern">
      <div className="leftConcern">
        <div className="leftConcernBox">
          <div className="concernTitle">
            <h3>You are the expert on what is important for you.</h3>
            <h5>What else do you need to help you make your decision?</h5>
          </div>

          <div className="concernQuestions">
            <h5>Questions</h5>
            <p>
              It may be helpful for you to talk with your family and friends.
              You may want to share the information in this decision aid with
              them. You should also share with your doctor your questions and
              concerns before making a final decision. It is important that you
              have all of the information you need to make a decision that is
              right for you. You have the right to make your own choices!
            </p>
            <div className="boxBubble">
              <SpeechBubble content={bubbleContent} />
            </div>
          </div>

          <div className="textConcern">
            <h5>What questions do you have?</h5>
            <textarea
              onChange={(e) => setQuestions(e.target.value)}
              // onKeyDown={ handleKeyPress}
              placeholder="Enter Text here"
              value={Questions}
              name=""
              id=""
              cols="70"
              rows="9"
            ></textarea>
          </div>
        </div>
      </div>
      <div className="rightConcern">
        <div className="patientThoughtsConcern">
          <h3>Patient’s thoughts</h3>

          <div className="thoughtsConcern">
            <p>
              <span>Jim:</span> “The whole thing is just getting all the
              information from any source that you can. And take it all in, and
              the final decision is up to you. You have to make that decision,
              not your doctor. And too many patients think the doctor is God,
              but the doctor doesn’t know your body the way you do. So, the
              final decision is yours.”
            </p>

            <p>
              <span>Caroline:</span> “First of all, I think it’s a very personal
              choice. I think everybody needs to make their own decision. But, I
              think it needs to be an informed decision.”
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuestionConcerns;
