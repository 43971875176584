

export const CopySummary = (RangeValue, inputValues, Questions, rangeLabels) =>{
  let copyContent = document.createElement("div");
  copyContent.classList.add("pdaPdfInfo")
  copyContent.id = "copyContent";
  // copyContent.style.display = "none";
  copyContent.innerHTML = `
  <h5 style=" color: #348dcc; font-size: 13px; margin-bottom: 3px;">On a Scale...</h5>
    <h6 style=" font-weight: bold; font-size: 13px; margin-bottom: 3px;">How would you like to live out the rest of your life?</h6>
    <p style=" color: gray; font-size: 13px; margin-bottom: 3px; color: black; font-weight: normal;">
    ${rangeLabels[RangeValue]}
    </p>
    <h5 style=" color: #348dcc; font-size: 13px; margin-bottom: 3px;">Reflections...</h5>
    <h6 style=" font-weight: bold; font-size: 13px; margin-bottom: 3px;">What benefits do you think you might experience:</h6>
    <p style=" color: gray; font-size: 13px; margin-bottom: 3px;">
        With an ICD: <span style=" color: black; font-size: 13px; margin-bottom: 3px; font-weight: normal;">${inputValues[1].WithIcd
          ? inputValues[1].WithIcd
          : "-"}</span>
      </p>
    <p style=" color: gray; font-size: 13px; margin-bottom: 3px;">
        Without an ICD: <span style=" color: black; font-size: 13px; margin-bottom: 3px; font-weight: normal;">${inputValues[1].WithoutIcd ? inputValues[1].WithoutIcd : "-"}</span>
      </p>
    <h6 style=" font-weight: bold; font-size: 13px; margin-bottom: 3px;">What losses do you think you might experience:</h6>
    <p style=" color: gray; font-size: 13px; margin-bottom: 3px;">
    With an ICD: <span style=" color: black; font-size: 13px; margin-bottom: 3px; font-weight: normal;">${inputValues[2].WithIcd ? inputValues[2].WithIcd : "-"}</span>
  </p>
  <p style=" color: gray; font-size: 13px; margin-bottom: 3px;">
    Without an ICD: <span style=" color: black; font-size: 13px; margin-bottom: 3px; font-weight: normal;">${inputValues[2].WithoutIcd ? inputValues[2].WithoutIcd : "-"}</span>
  </p>
    <h6 style=" font-weight: bold; font-size: 13px; margin-bottom: 3px;">How might your life change:</h6>
    <p style=" color: gray; font-size: 13px; margin-bottom: 3px;">
        With an ICD: <span style=" color: black; font-size: 13px; margin-bottom: 3px; font-weight: normal;">${inputValues[3].WithIcd ? inputValues[3].WithIcd : "-"}</span>
      </p>
      <p style=" color: gray; font-size: 13px; margin-bottom: 3px;">
        Without an ICD: <span style=" color: black; font-size: 13px; margin-bottom: 3px; font-weight: normal;">${inputValues[3].WithoutIcd ? inputValues[3].WithoutIcd : "-"}</span> 
      </p>
    <h6 style=" font-weight: bold; font-size: 13px; margin-bottom: 3px;">What frightens you about living with or without an ICD:</h6>
    <p style=" color: gray; font-size: 13px; margin-bottom: 3px;">
        With an ICD: <span style=" color: black; font-size: 13px; margin-bottom: 3px; font-weight: normal;">${inputValues[4].WithIcd
          ? inputValues[4].WithIcd
          : "-"}</span> 
      </p>
      <p style=" color: gray; font-size: 13px; margin-bottom: 3px;">
        Without an ICD: <span style=" color: black; font-size: 13px; margin-bottom: 3px; font-weight: normal;">${inputValues[4].WithoutIcd ? inputValues[4].WithoutIcd : "-"}</span> 
        
      </p>
    <h5 style=" color: #348dcc; font-size: 13px; margin-bottom: 3px;">Questions...</h5>
     <p style=" color: gray; font-size: 13px; margin-bottom: 3px; font-weight: bold;"> <span style=" color: black; font-size: 13px; margin-bottom: 3px; font-weight: normal;">${Questions}</span></p>
`;

return copyContent
}
