import React from "react";
import "./ConsiderIcd.scss";
import LifeStyleTopic from "../../LifeStyleTopic/LifeStyleTopic";
import ClickImage from "../../reuse/ClickImage/ClickImage";

const ConsiderIcd = () => {
  return (
    <div className="considerIcd">
      <div className="considerIcdInfo">
        <div className="considerIcdInfoSection">
          <h4>My doctor has asked me to consider an ICD. Why?</h4>
          <p>
            Due to your heart failure, you are at higher risk for developing a
            dangerous heart rhythm. A dangerous heart rhythm can cause you to
            die within minutes if not treated.
          </p>
        </div>

        <div className="considerIcdInfoSection">
          <h4>Does getting an ICD require surgery?</h4>
          <p>
            Yes. The ICD is put under the skin and one or more wires (called
            “leads”) are put into the heart. The surgery takes a few hours.
          </p>
        </div>

        <div className="considerIcdInfoSection">
          <h4>Will an ICD make me feel better?</h4>
          <p>
            ICDs do not make you feel better. Some patients might get devices
            with other features that can make them feel better. You should talk
            with your doctor about these devices
          </p>
        </div>

        <div className="considerIcdInfoSection">
          <h4>Will I get shocked?</h4>
          <p>
            Over 5 years, about 20 out of every 100 patients get shocked by
            their ICDs. About 80 out of every 100 will not get shocked. Most
            shocks happen because of dangerous heart rhythms but some happen
            when they are not needed.
          </p>
        </div>

        <div className="considerIcdInfoSection">
          <h4>How does it feel to receive a shock?</h4>
          <p>
            Patients say that getting shocked is like “being kicked in the
            chest.” Some patients pass out before they are shocked and do not
            remember being shocked. Before a shock is delivered, the ICD will
            try to correct your dangerous heart rhythm.
          </p>
        </div>
      </div>
      <div className="considerIcdCards">
        <LifeStyleTopic />
        <ClickImage />
      </div>
    </div>
  );
};

export default ConsiderIcd;
