import React, { useRef } from "react";
import "./OptionGrid.scss";
import ReactToPrint from "react-to-print";

const OptionGrid = () => {
  const componentRef = useRef();

  const handlePrint = () => {
    window.print();
  };

  return (
    <div ref={componentRef} className="optionGrid">
      <h2>Implantable Cardioverter-Defibrillators (ICD)</h2>
      <div className="optionGridHead">
        <h4>In Summary</h4>
        <ReactToPrint
          trigger={() => (
            <button className={"printButton"} onClick={handlePrint}>
              Print Option Grid
            </button>
          )}
          content={() => componentRef.current}
        />
      </div>
      <div className="optionGridTable">
        <table>
          <thead>
            <tr>
              <th>FAQ</th>
              <th>Implant an ICD</th>
              <th>Do not implant an ICD</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>What does an ICD do?</td>
              <td>
                An ICD may stop a dangerous heart rhythm that could cause sudden
                death by giving an electrical shock to the heart.
              </td>
              <td>
                Without an ICD, you will have a higher risk of dying suddenly if
                a dangerous heart rhythm happens.
              </td>
            </tr>
            <tr>
              <td>What is involved?</td>
              <td>
                An ICD is put under the skin on your chest and wires (”leads”)
                go into your heart. You will probably stay one night in the
                hospital. In about 5-10 years, when the battery runs out, the
                ICD will need to be replaced.
              </td>
              <td>
                You can continue to use medicine to treat your heart problem.
              </td>
            </tr>
            <tr>
              <td>Will I live longer with an ICD?</td>
              <td>
                Patients with an ICD are less likely to die suddenly of a
                dangerous heart rhythm. With an ICD, 29 out of 100 patients with
                heart failure will die over a 5-year period. This is 7 fewer
                deaths than if they did not have an ICD.
              </td>
              <td>
                Patients without an ICD are more likely to die suddenly from a
                dangerous heart rhythm. Without an ICD, 36 out of 100 patients
                with heart failure will die over a 5- year period.
              </td>
            </tr>
            <tr>
              <td>Will I get shocked by the ICD? What will that feel like?</td>
              <td>
                Over 5 years, 20 out of every 100 patients who have an ICD will
                get a shock. 80 out of 100 patients will not get shocked.
                Patients say that getting shocked is like “being kicked in the
                chest.”
              </td>
              <td>
                You will not get a shock from an ICD if you don’t get an ICD.
              </td>
            </tr>
            <tr>
              <td>What are the risks of getting</td>
              <td>
                4 out of every 100 patients will have some bleeding. 2 out of
                every 100 patients will have a serious problem, such as damage
                to the lung, a heart attack, or a stroke. 1 out of every 100
                patients will get an infection, which may require removing the
                ICD.
              </td>
              <td>You will not have the risks of placing an ICD.</td>
            </tr>
            <tr>
              <td>Will an ICD improve my symptoms?</td>
              <td>
                Having an ICD will not improve your symptoms or cure your heart
                problem.
              </td>
              <td>Your symptoms will be influenced by your heart failure.</td>
            </tr>
            <tr>
              <td>Are there things I cannot do?</td>
              <td>
                This depends on your heart problem. Talk to your doctor about
                driving limitations and other activities.
              </td>
              <td>
                Even without an ICD, talk with your doctor about driving
                limitations and other activities.
              </td>
            </tr>
            <tr>
              <td>Can the ICD be taken out?</td>
              <td>
                It is best not to remove the ICD unless it gets infected or it
                is time to have it replaced when the battery runs out.
              </td>
              <td>Does not apply</td>
            </tr>
            <tr>
              <td>Can the ICD be turned off?</td>
              <td>
                Yes, the ICD can be turned o- without surgery. This is
                recommended if a person is likely to die from another illness.
              </td>
              <td>Does not apply.</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default OptionGrid;
