import axios from "axios";
import { API_URL } from "../constant";

const instance = axios.create({
  baseURL: API_URL,
  headers: {
    "Access-Control-Allow-Origin": "*"
  }
});

export default instance;
