import React from "react";
import "./RightBar.scss";
import { useState } from "react";
import Footer from "../reuse/Footer/Footer";
import What from "../pages/What/What";
import ConsiderIcd from "../pages/ConsiderIcd/ConsiderIcd";
import Survival from "../pages/Survival/Survival";
import ValuesPages from "../pages/ValuesPages/ValuesPages";
import OptionGrid from "../pages/OptionGrid/OptionGrid";
import QuestionConcerns from "../pages/QuestionConcerns/QuestionConcerns";
import PatientSummary from "../pages/PatientSummary/PatientSummary";

const RightBar = () => {
  const [selectedButton, setSelectedButton] = useState(1);
  const [RangeValue, setRangeValue] = useState(3);

  // for Reflection state keys 
  const [inputValues, setInputValues] = useState({
    1: {
      WithIcd: "",
      WithoutIcd: "",
    },
    2: {
      WithIcd: "",
      WithoutIcd: "",
    },
    3: {
      WithIcd: "",
      WithoutIcd: "",
    },
    4: {
      WithIcd: "",
      WithoutIcd: "",
    },
  });

  // const [Reflections, setReflections] = useState({
  //   benefits: "",
  //   losses: "",
  //   lifeChange: "",
  //   frightens: "",
  // });
  const [Questions, setQuestions] = useState("");

  const buttons = [
    { id: 1, label: "What" },
    { id: 2, label: "Considering an ICD" },
    { id: 3, label: "Survival" },
    { id: 4, label: "Values & Wishes" },
    { id: 5, label: "Option Grid" },
    { id: 6, label: "Questions & Concerns" },
    { id: 7, label: "Patient Summary" },
  ];

  const handleButtonClick = (buttonId) => {
    setSelectedButton(buttonId);
  };

  const componentMap = {
    1: <What />,
    2: <ConsiderIcd />,
    3: <Survival />,
    4: (
      <ValuesPages
        inputValues={inputValues}
        setInputValues={setInputValues}
        RangeValue={RangeValue}
        setRangeValue={setRangeValue}
      />
    ),
    5: <OptionGrid />,
    6: (
      <QuestionConcerns
        Questions={Questions}
        setQuestions={setQuestions}
      />
    ),
    7: (
      <PatientSummary
        Questions={Questions}
        inputValues={inputValues}
        RangeValue={RangeValue}
      />
    ),
  };

  return (
    <div className="rightBar">
      <div className="topButtons">
        {buttons.map((button) => (
          <button
            key={button.id}
            className={`button ${
              selectedButton === button.id ? "selected" : ""
            }`}
            onClick={() => handleButtonClick(button.id)}
          >
            {button.label}
          </button>
        ))}
      </div>

      <div className="rightBody">
      {componentMap[selectedButton] || null}
      </div>

      <Footer />
    </div>
  );
};

export default RightBar;
