import axios from '../../utils/getApi'
import { call, put, takeLatest } from 'redux-saga/effects'
import Actions from '../store/actions';

function getApiCall(){
    return axios.get(``)
}

function* watchGetApiCallAsync(data) {
    try {
      const details = yield call(getApiCall, data);
      yield put({ type: Actions.GET_API_SUCCESS, details });
    } catch (error) {
      yield put({
        type: Actions.GET_API_FAIL,
        error,
      });
    }
  }

const icdSaga = [
    takeLatest(Actions.GET_API, watchGetApiCallAsync),
]

export default icdSaga

