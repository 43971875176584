import React from "react";
import "./Survival.scss";
import roundSlash from '../../../assets/images/no_icd.png'
import icdIcon from '../../../assets/images/yes_icd.png'
import humanChart from '../../../assets/images/benefits.png'

const Survival = () => {
  return (
    <div className="survival">
      <div className="survivalFirstQuestion">
        <h4>Would I survive a dangerous heart rhythm without an ICD?</h4>
        <p>
          You may survive a dangerous heart rhythm only if you are treated
          within a few minutes with an external shock. However, many patients
          die before emergency help can reach them.
        </p>
      </div>

      <div className="survivalSecondQuestion">
        <h4>Will I live longer with an ICD?*</h4>

        <div className="survivalQuestionPoints">
          <img src={roundSlash} alt="" />
          <p>
            <span>Without an ICD:</span> Patients without an ICD are more likely
            to die suddenly from a dangerous heart rhythm. Without an ICD, over
            5 years, 36 out of every 100 patients with heart failure will die
            over a 5-year period.{" "}
          </p>
        </div>

        <div className="survivalQuestionPoints">
            <img src={icdIcon} alt="" />
          <p>
            <span>With an ICD:</span> Patients with an ICD are less likely to
            die suddenly of a dangerous heart rhythm. With an ICD, 29 out of
            every 100 patients with heart failure will die over a 5-year period.
            This means 7 more patients would live with an ICD over a 5- year
            period.{" "}
          </p>
        </div>
      </div>

      <div className="survivalChart">
        <p>The numbers below are from recent medical studies. However, no one can know what will happen to any one person.</p>

        <img src={humanChart} alt="" />
      </div>
    </div>
  );
};

export default Survival;
