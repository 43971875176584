import { applyMiddleware, combineReducers, createStore } from 'redux'
import reducers from './reducers'
import createSagaMiddleware from '@redux-saga/core'
import rootSaga from './saga'

const reducer = combineReducers(reducers)
const sagaMiddleware = createSagaMiddleware()

const store = createStore(reducer, applyMiddleware(sagaMiddleware))

sagaMiddleware.run(rootSaga)

export default store