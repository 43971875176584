import React from "react";
import "./ScaleBar.scss";
//import scale from "../../../assets/images/scale.png";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";

const ScaleBar = ({ RangeValue, setRangeValue }) => {
  console.log(RangeValue);

  const renderTickMarks = () => {
    const ticks = Array.from({ length: 7 }, (_, index) => index);
    return ticks.map((tick) => (
      <div key={tick} className="tick-mark" style={{ left: `${(tick / 6) * 100}%` }} />
    ));
  };


  return (
    <div className="ScaleBar">
      <div className="scaleBarRange">
        {/* <input
          className={"normalScale"}
          onChange={(e) => {
            const rangeValue = parseInt(e.target.value);
            setRangeValue(rangeValue);
          }}
          type="range"
          min="0"
          max="6"
          value={RangeValue}
          step="1"
          list="steplist"
        /> */}

        {/* <datalist id="steplist">
          <option>0</option>
          <option>1</option>
          <option>2</option>
          <option>3</option>
          <option>4</option>
          <option>5</option>
          <option>6</option>
        </datalist> */}

        <Slider
          className="normalScale"
          onChange={setRangeValue}
          min={0}
          max={6}
          step={1}
          value={RangeValue}
        />
        <div className="tick-marks-container">{renderTickMarks()}</div>
      </div>
    </div>
  );
};

export default ScaleBar;
