import React from 'react'
import './LifeStyleTopic.scss'
import {PiMagnetBold} from 'react-icons/pi'
import {FaSuitcase, FaCarSide} from 'react-icons/fa'
import {GiSewingMachine} from 'react-icons/gi'
import {BiRun} from 'react-icons/bi'
import {BsFillPhoneVibrateFill} from 'react-icons/bs'

const LifeStyleTopic = () => {
  return (
    <div className='lifestyleTopic'>
        <div className="lifestyleTopicHead">
            <p>Lifestyle</p>
            <p>Discussion Topics</p>
        </div>

        <div className="lifestyleIconPoints">
            <div className="lifestyleIcon">
                <PiMagnetBold/>
                <p>Magnets</p>
            </div>
            <div className="lifestyleIcon">
                <FaSuitcase/>
                <p>Airport Security</p>
            </div>
            <div className="lifestyleIcon">
                <GiSewingMachine/>
                <p>Machinery</p>
            </div>
            <div className="lifestyleIcon">
                <FaCarSide/>
                <p>Driving</p>
            </div>
            <div className="lifestyleIcon">
                <BiRun/>
                <p>Sex/Exercise</p>
            </div>
            <div className="lifestyleIcon">
                <BsFillPhoneVibrateFill/>
                <p>Cell Phone</p>
            </div>
        </div>
    </div>
  )
}

export default LifeStyleTopic