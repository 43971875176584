import React from "react";
import "./LeftBar.scss";
import AboutPatient from "../AboutPatient/AboutPatient";

const LeftBar = ({cql}) => {

  return (
    <div className="leftBar">
      <p className="aid">A decision aid for</p>
      <h4 className="ICDTtitle">Implantable Cardioverter- Defibrillators (ICD)</h4>
      <p className="baseDesc">
        For patients with heart failure considering an ICD who are at risk for
        sudden cardiac death (primary prevention).
      </p>

      <AboutPatient cql={cql} />
    </div>
  );
};

export default LeftBar;
