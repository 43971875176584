import React, {useState} from 'react'
import './ClickImage.scss'
import ImagePop from '../popups/ImagePop/ImagePop'
import icdSmallDevice from '../../../assets/images/FPO_sample-product-coin.jpg'

const ClickImage = () => {
  const [OpenPop, setOpenPop] = useState(false)
  return (
    <div className='clickImage'>
       <p>Click on image for life-size ICD:</p>
       <img onClick={() => setOpenPop(true)} src={icdSmallDevice} alt="" />

       {OpenPop && (<> <ImagePop setOpenPop={setOpenPop} /> </>)}
    </div>
  )
}

export default ClickImage