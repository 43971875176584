import React from "react";
import "./PatientSummary.scss";
import toastr from "../../reuse/toaster/Toastr";
import { CopySummary } from "./CopySummary";

const PatientSummary = ({ RangeValue, inputValues, Questions }) => {
  const rangeLabels = [
    "Very important - Die Quickly",
    "Important - Die Quickly",
    "Slightly important - Die Quickly",
    "Neutral",
    "Slightly important - Live as long as possible",
    "Important - Live as long as possible",
    "Very importnant - Live as long as possible",
  ];
  const content = (
    <>
      {" "}
      <div className="scaleSummary">
        <h5>On a Scale...</h5>
        <h6>How would you like to live out the rest of your life?</h6>
        <p>{rangeLabels[RangeValue]}</p>
      </div>
      <div className="reflectionSummary">
        <h5>Reflections...</h5>
        <h6>What benefits do you think you might experience:</h6>
        <p>
          {" "}
          With an ICD:{" "}
          <span>{inputValues[1].WithIcd ? inputValues[1].WithIcd : "-"}</span>
        </p>
        <p>
          {" "}
          Without an ICD:{" "}
          <span>
            {inputValues[1].WithoutIcd ? inputValues[1].WithoutIcd : "-"}
          </span>
        </p>
        <h6>What losses do you think you might experience:</h6>
        <p>
          {" "}
          With an ICD:{" "}
          <span>{inputValues[2].WithIcd ? inputValues[2].WithIcd : "-"}</span>
        </p>
        <p>
          {" "}
          Without an ICD:{" "}
          <span>
            {inputValues[2].WithoutIcd ? inputValues[2].WithoutIcd : "-"}
          </span>
        </p>
        <h6>How might your life change:</h6>
        <p>
          With an ICD:{" "}
          <span>{inputValues[3].WithIcd ? inputValues[3].WithIcd : "-"}</span>
        </p>
        <p>
          {" "}
          Without an ICD:{" "}
          <span>
            {inputValues[3].WithoutIcd ? inputValues[3].WithoutIcd : "-"}
          </span>
        </p>
        <h6>What frightens you about living with or without an ICD:</h6>
        <p>
          With an ICD:{" "}
          <span>{inputValues[4].WithIcd ? inputValues[4].WithIcd : "-"}</span>
        </p>
        <p>
          Without an ICD:{" "}
          <span>
            {inputValues[4].WithoutIcd ? inputValues[4].WithoutIcd : "-"}
          </span>
        </p>
      </div>
      <div className="questionSummary">
        <h5>Questions...</h5>
        <p>{Questions ? Questions : "-"}</p>
      </div>
    </>
  );

  const handleCopyToClipBoard = () => {
    let copyToClipBoardContent = CopySummary(
      RangeValue,
      inputValues,
      Questions,
      rangeLabels
    );
    const tempElement = document.createElement("div");
    tempElement.innerHTML = copyToClipBoardContent.innerHTML;

    // Insert the HTML content into the DOM
    document.body.appendChild(tempElement);
    const range = document.createRange();
    range.selectNode(tempElement);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);

    try {
      // Execute the copy command
      document.execCommand("copy");
      toastr.success("Copied to Clipboard");
      console.log("HTML content copied to clipboard:", copyToClipBoardContent);
    } catch (err) {
      console.error("Failed to copy HTML content:", err);
    }

    // Clean up by removing the temporary element
    document.body.removeChild(tempElement);
  };

  return (
    <div className="patientSummary">
      <h3>Patient Summary</h3>

      <div className="patientSummaryBody">
        <div className="summaryBodyContent">{content}</div>
      </div>
      <div className="summaryButton">
        <button onClick={handleCopyToClipBoard}>Copy to Clipboard</button>
      </div>
    </div>
  );
};

export default PatientSummary;
