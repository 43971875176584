import React from "react";
import "./AboutPatient.scss";
import moment from "moment/moment";

const AboutPatient = ({ cql }) => {
  console.log(
    "testQL",
    cql["Implantable defibrillator, device (physical object)"]
  );

  const ejecFracDate =
    cql["Left ventricular Ejection fraction (Most Recent)"] &&
    cql["Left ventricular Ejection fraction (Most Recent)"]._json.meta
      .lastUpdated;
  let formatedEjecFracDate = moment(ejecFracDate).format("MM/DD/YYYY");

  const ejecFracLowestDate =
    cql["Left ventricular Ejection fraction (Lowest)"] &&
    cql["Left ventricular Ejection fraction (Lowest)"]._json.meta.lastUpdated;
  let formatedEjectLowestFracDate =
    moment(ejecFracLowestDate).format("MM/DD/YYYY");

  const qtDate =
    cql["Q-T interval corrected (Most Recent)"] &&
    cql["Q-T interval corrected (Most Recent)"]._json.meta.lastUpdated;
  let formatedQtDate = moment(qtDate).format("MM/DD/YYYY");

  const qrsDate =
    cql["QRS duration (Most Recent)"] &&
    cql["QRS duration (Most Recent)"]._json.meta.lastUpdated;
  let formatedQrsDate = moment(qrsDate).format("MM/DD/YYYY");

  return (
    <div className="aboutPatient">
      <h4>About the Patient</h4>

      <div className="aboutPatientTable">
        <div className="aboutPatientTableChild">
          <div className="aboutPatientTableTitle">
            <p>Ejection Fraction</p>
          </div>
          <div className="aboutPatientTableInfo">
            <ul>
              <li>
                <p>
                  <span className="liBoldTitle">Most Recent:</span>{" "}
                  {cql["Left ventricular Ejection fraction (Most Recent)"]
                    ? cql["Left ventricular Ejection fraction (Most Recent)"]
                        ._json.valueQuantity.value
                    : "-"}{" "}
                  <span>
                    {" "}
                    {cql["Left ventricular Ejection fraction (Most Recent)"] &&
                      cql["Left ventricular Ejection fraction (Most Recent)"]
                        ._json.valueQuantity.unit}{" "}
                  </span>{" "}
                </p>
                <p>
                  date:{" "}
                  {formatedEjecFracDate !== "Invalid date"
                    ? formatedEjecFracDate
                    : "-"}{" "}
                </p>
              </li>
              <li>
                <p>
                  <span className="liBoldTitle">Lowest:</span>{" "}
                  {cql["Left ventricular Ejection fraction (Lowest)"]
                    ? cql["Left ventricular Ejection fraction (Lowest)"]._json
                        .valueQuantity.value
                    : "-"}{" "}
                  <span>
                    {" "}
                    {cql["Left ventricular Ejection fraction (Lowest)"] &&
                      cql["Left ventricular Ejection fraction (Lowest)"]._json
                        .valueQuantity.unit}{" "}
                  </span>{" "}
                </p>
                <p>
                  date:{" "}
                  {formatedEjectLowestFracDate !== "Invalid date"
                    ? formatedEjectLowestFracDate
                    : "-"}{" "}
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div className="aboutPatientTableChild">
          <div className="aboutPatientTableTitle">
            <p>QTc Interval</p>
          </div>
          <div className="aboutPatientTableInfo">
            <ul>
              <li>
                <p>
                  {" "}
                  {cql["Q-T interval corrected (Most Recent)"]
                    ? cql["Q-T interval corrected (Most Recent)"]._json
                        .valueQuantity.value
                    : "-"}{" "}
                  <span>
                    {" "}
                    {cql["Q-T interval corrected (Most Recent)"] &&
                      cql["Q-T interval corrected (Most Recent)"]._json
                        .valueQuantity.unit}{" "}
                  </span>{" "}
                </p>
                <p>
                  date:{" "}
                  {formatedQtDate !== "Invalid date" ? formatedQtDate : "-"}
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div className="aboutPatientTableChild">
          <div className="aboutPatientTableTitle">
            <p>QRS Interval</p>
          </div>
          <div className="aboutPatientTableInfo">
            <ul>
              <li>
                <p>
                  {cql["QRS duration (Most Recent)"]
                    ? cql["QRS duration (Most Recent)"]._json.valueQuantity
                        .value
                    : "-"}{" "}
                  <span>
                    {cql["QRS duration (Most Recent)"] &&
                      cql["QRS duration (Most Recent)"]._json.valueQuantity
                        .unit}
                  </span>
                </p>
                <p>
                  date:{" "}
                  {formatedQrsDate !== "Invalid date" ? formatedQrsDate : "-"}
                </p>
              </li>
            </ul>
          </div>
        </div>
        {/* <div className="aboutPatientTableChild">
          <div className="aboutPatientTableTitle">
            <p>Severity of Illness Index</p>
          </div>
          <div className="aboutPatientTableInfo">
            <ul>
              <li>
                <p>Major</p>
                <p>date: 05/10/2021</p>
              </li>
            </ul>
          </div>
        </div> */}
        <div className="aboutPatientTableChild">
          <div className="aboutPatientTableTitle">
            <p>Active Palliative Care</p>
          </div>
          <div className="aboutPatientTableInfo">
            <ul>
              <li>
                <p>{cql["Palliative Care"] ? "Yes" : "No"}</p>
                {/* <p>date: 01/15/2022</p> */}
              </li>
            </ul>
          </div>
        </div>
        <div className="aboutPatientTableChild">
          <div className="aboutPatientTableTitle">
            <p>Has an ICD</p>
          </div>
          <div className="aboutPatientTableInfo">
            <ul>
              <li>
                <p>
                  {" "}
                  {cql["Implantable defibrillator, device (physical object)"]
                    ? "Yes"
                    : "No"}{" "}
                </p>
                {/* <p>date: 09/09/2020</p>
                <p>battery: 60%</p> */}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutPatient;
