import React from 'react'
import './TableInput.scss'

const TableInput = ({placeholder, value, onChange, name}) => {
  return (
    <>
        <textarea className='tableInput' name={name} onChange={onChange} value={value} type="text" placeholder={placeholder} cols="30" rows="2"></textarea>
    </>
  )
}

export default TableInput