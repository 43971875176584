import React from "react";
import "./ImagePop.scss";
import icdDeviceBig from '../../../../assets/images/FPO_sample-product-coin.jpg'

const ImagePop = ({ setOpenPop }) => {
  return (
    <div className="popupOverlay">
      <div className="popupContent">
        <div className="contentTitleImg">
          <h2>Life-size ICD images</h2>
          <img
            className="popupImage"
            onClick={() => setOpenPop(true)}
            src={icdDeviceBig}
            alt=""
          />
        </div>

        <div className="closeButton">
          <button onClick={() => setOpenPop(false)} className="closePopupBtn">
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default ImagePop;
