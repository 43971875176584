import React from 'react'
import './HomePage.scss'
import LeftBar from '../LeftBar/LeftBar'
import RightBar from '../RightBar/RightBar'

const HomePage = ({cql}) => {
  return (
    <div>
       <div className="mainBody">
        <LeftBar cql={cql} />
        <RightBar />
       </div>
    </div>
  )
}

export default HomePage