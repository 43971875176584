import Actions from "./actions";

const defaultState = {
  getApiData: null,
  getApiDataSuccess: false,
  getApiDataLoading: false,
  getApiDataFail: false,
};

const icdReducer = (state = defaultState, action) => {
  switch (action.type) {
    case Actions.GET_API:
      return {
        ...state,
        getApiDataSuccess: false,
        getApiDataLoading: true,
        getApiDataFail: false,
      };
    case Actions.GET_API_SUCCESS:
      return {
        ...state,
        getApiDataSuccess: true,
        getApiDataLoading: false,
        getApiDataFail: false,
        getApiData: action.details,
      };
    case Actions.GET_API_FAIL:
      return {
        ...state,
        getApiDataSuccess: false,
        getApiDataLoading: false,
        getApiDataFail: true,
      };

    default:
      return {
        ...state,
      };
  }
};

export default icdReducer;
