import React from "react";
import "./ValuesPages.scss";
import ScaleBar from "../../reuse/ScaleBar/ScaleBar";
import TableInput from "../../reuse/TableInput/TableInput";
import path1Chart from "../../../assets/images/path1.png";
import path2Chart from "../../../assets/images/path2.png";
import SpeechBubble from "../../reuse/SpeechBubble/SpeechBubble";

const ValuesPages = ({
  RangeValue,
  setRangeValue,
  setInputValues,
  inputValues,
}) => {
  // states to get the input user values

  const handleInputChange = (event, tdId, icdType) => {
    const { value } = event.target;

    // Update the specific input's value based on tdId and icdType
    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [tdId]: {
        ...prevInputValues[tdId],
        [icdType]: value,
      },
    }));
  };

  const bubbleContent =
    "ICDs have to be replaced every 5 to 10 years when the battery wears down. This requires another surgery. Replacing ICD wires is rare but is sometimes required.";

  return (
    <div className="ValuesPages">
      <div className="valuesChartblock">
        <h3>
          While the future is always unpredictable, there is an important trade
          to consider when deciding whether to get an ICD.
        </h3>

        <div className="valuesCharts">
          <div className="valuesChartBoxes">
            {/* <button>Path 1</button>
            <div className="chartBoxBody">
              <p>
                You may choose to get an ICD. You may be feeling like you
                usually do, then a dangerous heart rhythm could happen. The ICD
                may help you live longer by treating a dangerous heart rhythm.
                You will continue to live with heart failure that may get worse
                over time.
              </p>
              <div className="chartBoxImageGraph">
                <img src={path1Chart} alt="" />
                <p>Last Years of Life</p>
              </div>
              <p>
                "I'm not ready to die. I have so much I'm trying to stay alive
                for. Even if it means getting shocked, I'm willing to do
                anything that can help me live longer."
              </p>
            </div> */}

            <img src={path1Chart} alt="" />
          </div>

          <div className="valuesChartBoxes">
            {/* <button>Path 2</button>
            <div className="chartBoxBody">
              <p>
                You may choose to NOT get an ICD. You may be feeling like you
                usually do, and then a dangerous heart rhythm could happen. You
                may die quick- ly from the dangerous heart rhythm. This can
                happen at any time.
              </p>
              <div className="chartBoxImageGraph">
                <img src={path2Chart} alt="" />
                <p>Last Years of Life</p>
              </div>
              <p>
                "I've lived a good life. The idea of dying quickly sounds like a
                painless way to go. I've always said I hope to die in my sleep.
                Going through surgery and getting shocked is not the kind of
                thing I want."
              </p>
            </div> */}

            <img src={path2Chart} alt="" />
          </div>
        </div>
      </div>

      <div className="valuesEnd">
        <h5>Can the ICD be taken out?</h5>
        <p>
          It is best not to remove the ICD unless you have an infection or are
          having the ICD replaced.
        </p>
        {/* <img src={chatImage} alt="" /> */}
        <div className="boxBubble">
          <SpeechBubble content={bubbleContent} />
        </div>
      </div>

      <div className="valuesScale">
        <div className="valuesScaleInfo">
          <h4>On a Scale...</h4>
          <p>
            While no-one can predict the future, if you were able to choose, how
            would you like to live out the rest of your life? (slide to select
            option)
          </p>
        </div>

        <div className="valueScaleSection">
          <div>
            <p>
              Die quickly (for example, dying suddenly in your sleep) and not
              live as long.
            </p>
          </div>
          <div className="scaleBar">
            <ScaleBar RangeValue={RangeValue} setRangeValue={setRangeValue} />
          </div>
          <div>
            <p>
              Live as long as possible even with an illness like heart failure
              that may get worse over time.
            </p>
          </div>
        </div>

        <div className="valueScaleTableBox">
          <div className="valueScaleTable">
            <table>
              <thead>
                <tr>
                  <th>Reflection...</th>
                  <th>With an ICD?</th>
                  <th>Without an ICD?</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>What benefits do you think you might experience:</td>
                  <td>
                    <TableInput
                      name="WithIcd1"
                      value={inputValues[1].WithIcd}
                      onChange={(event) =>
                        handleInputChange(event, 1, "WithIcd")
                      }
                      placeholder="Enter reflection text here"
                    />
                  </td>
                  <td
                  // onMouseEnter={() => handleMouseEnter(2)}
                  // // onMouseLeave={() => handleMouseLeave()}
                  >
                    <TableInput
                      name="WithoutIcd1"
                      value={inputValues[1].WithoutIcd}
                      onChange={(event) =>
                        handleInputChange(event, 1, "WithoutIcd")
                      }
                      placeholder="Enter reflection text here"
                    />
                  </td>
                </tr>
                <tr>
                  <td>What losses do you think you might experience:</td>
                  <td
                  // onMouseEnter={() => handleMouseEnter(3)}
                  // // onMouseLeave={handleMouseLeave}
                  >
                    <TableInput
                      name="WithIcd2"
                      value={inputValues[2].WithIcd}
                      onChange={(event) =>
                        handleInputChange(event, 2, "WithIcd")
                      }
                      placeholder="Enter reflection text here"
                    />
                  </td>
                  <td
                  // onMouseEnter={() => handleMouseEnter(4)}
                  // // onMouseLeave={handleMouseLeave}
                  >
                    <TableInput
                      name="WithoutIcd2"
                      value={inputValues[2].WithoutIcd}
                      onChange={(event) =>
                        handleInputChange(event, 2, "WithoutIcd")
                      }
                      placeholder="Enter reflection text here"
                    />
                  </td>
                </tr>
                <tr>
                  <td>How might your life change:</td>
                  <td
                  // onMouseEnter={() => handleMouseEnter(5)}
                  // // onMouseLeave={handleMouseLeave}
                  >
                    <TableInput
                      name="WithIcd3"
                      value={inputValues[3].WithIcd}
                      onChange={(event) =>
                        handleInputChange(event, 3, "WithIcd")
                      }
                      placeholder="Enter reflection text here"
                    />
                  </td>
                  <td
                  // onMouseEnter={() => handleMouseEnter(6)}
                  // // onMouseLeave={handleMouseLeave}
                  >
                    <TableInput
                      name="WithoutIcd3"
                      value={inputValues[3].WithoutIcd}
                      onChange={(event) =>
                        handleInputChange(event, 3, "WithoutIcd")
                      }
                      placeholder="Enter reflection text here"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    What frightens you about living with or without an ICD:
                  </td>
                  <td
                  // onMouseEnter={() => handleMouseEnter(7)}
                  // // onMouseLeave={handleMouseLeave}
                  >
                    <TableInput
                      name="WithIcd4"
                      value={inputValues[4].WithIcd}
                      onChange={(event) =>
                        handleInputChange(event, 4, "WithIcd")
                      }
                      placeholder="Enter reflection text here"
                    />
                  </td>
                  <td
                  // onMouseEnter={() => handleMouseEnter(8)}
                  // // onMouseLeave={handleMouseLeave}
                  >
                    <TableInput
                      name="WithoutIcd4"
                      value={inputValues[4].WithoutIcd}
                      onChange={(event) =>
                        handleInputChange(event, 4, "WithoutIcd")
                      }
                      placeholder="Enter reflection text here"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ValuesPages;
