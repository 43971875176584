import React from "react";
import "./SpeechBubble.scss";
import { FaUserDoctor } from "react-icons/fa6";

const SpeechBubble = ({content}) => {
  return (
    <div className="speechBubble">
      <div className="dotorAvatar">
        <FaUserDoctor />
      </div>

      <div className="mainBubble">
        <div className="bubbleleftArrow"></div>

        <div className="bubbleContent">
          <p>
            {content}
          </p>
        </div>
      </div>
    </div>
  );
};

export default SpeechBubble;
